<template>
  <div>
    <div style="height: 100vh" class="all">
      <md-scroll-view
        ref="scrollView"
        auto-reflow
        :scrolling-x="false"
        @end-reached="loadMorechange"
        v-show="history.length > 0"
      >
        <div v-for="(item, index) in history" :key="index" class="history_jhs">
          <div class="make_time">
            <div
              :style="{ backgroundColor: getStorage('theme') }"
              class="back_color"
            ></div>
            <div class="time">制作时间：{{ item.intime }}</div>
          </div>
          <div @click="tz_report(item)" class="heade">
            <div class="text">
              <img :src="`${item.cpurl}`" alt="" />
              <div>
                <div>{{ item.cpname }}</div>
                <div>
                  {{ item.pidsex }} {{ item.pidage }}岁 首年总保费：{{
                    item.khinfo.allmount
                  }}
                </div>
              </div>
            </div>
            <md-icon name="arrow-right"></md-icon>
          </div>
          <div v-if="item.te_ic == true">
            <table>
              <thead>
                <tr>
                  <td>险种</td>
                  <td>保额</td>
                  <td>保费</td>
                  <td>交费期</td>
                </tr>
              </thead>
                <tbody v-for="(ite, ind) in item.xzlist.slice(0, 2)" :key="ind">
                  <tr>
                    <td>
                      <div class="td">
                        <img
                          v-if="ite.appf == 1"
                          src="@/assets/jhs/img/zhu_zhu.png"
                          alt=""
                        />
                        <img
                          v-if="ite.appf == 2"
                          src="@/assets/jhs/img/zhu_fu.png"
                          alt=""
                        />
                        <div>{{ ite.prodname }}</div>
                      </div>
                    </td>
                    <td>{{ ite.amount }}</td>
                    <td>{{ ite.yearmoney }}</td>
                    <td>{{ ite.yearnum }}</td>
                  </tr>
                </tbody>
               </table>
            </div>
            <div v-else>
              <table>
                <thead>
                  <tr>
                    <td>险种</td>
                    <td>保额</td>
                    <td>保费</td>
                    <td>交费期</td>
                  </tr>
                </thead>
                <tbody v-for="(ite, ind) in item.xzlist" :key="ind">
                  <tr>
                    <td>
                      <div class="td">
                        <img
                          v-if="ite.appf == 1"
                          src="@/assets/jhs/img/zhu_zhu.png"
                          alt=""
                        />
                        <img
                          v-if="ite.appf == 2"
                          src="@/assets/jhs/img/zhu_fu.png"
                          alt=""
                        />
                        <div>{{ ite.prodname }}</div>
                      </div>
                    </td>
                    <td>{{ ite.amount }}</td>
                    <td>{{ ite.yearmoney }}</td>
                    <td>{{ ite.yearnum }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            

         
          <div
            v-if="item.xzlist.length > 2 && item.te_ic == true"
            @click="bott(item, index)"
            class="zkmx"
          >
            展开查看详情
            <md-icon name="arrow-right"></md-icon>
          </div>
          <div
            v-if="item.xzlist.length > 2 && item.te_ic == false"
            @click="right(item, index)"
            class="zkmx"
          >
            点击关闭详情
            <md-icon name="arrow-down"></md-icon>
          </div>
          <div class="all_btn">
            <div class="del" @click="detail_jhs(item, index)">删除</div>
            <div class="can" @click="Usage_plan(item)">使用方案</div>
          </div>
        </div>
        <md-scroll-view-more slot="more" :is-finished="listFinished" />
      </md-scroll-view>
      <md-result-page
        :img-url="require('@/assets/abd/image/null.png')"
        v-if="history.length <= 0"
        subtext="您还未生成计划书"
      />
    </div>
  </div>
</template>
<script>
import { myJhsList, useJhsInfo, delJhsNo } from "@/api/jhs/productlist/index";
import { setStorage } from "@/lib/util";
import loadMorechange from "@/mixins/loadMorechange";
import { Toast, Dialog } from "mand-mobile";
export default {
  mixins: [loadMorechange],
  data() {
    return {
      parameter: {
        size: "10", //一页的数据量
      },
      listpageNo: "1", //页数
      history: [], //返回的数据赋值
      icon_is: "ind",
      //---------当长度大于2的时候显示展开查看详情------------
      text_icon_is: {
        te_ic: false,
      },
      //---------当长度大于2的时候显示展开查看详情------------
      // 上拉加载---------
      listtotalPage: 0,
      listFinished: false,
      loadflag: false,
      // -------------
    };
  },
  created() {
    this.getData("cre");
  },
  methods: {
    // 跳转到报告
    tz_report(item) {
      // console.log(item.jhsno);
      this.$router.push({
        path: "/responsibility",
        query: {
          jhsno: item.jhsno,
        },
      });
    },
    getData(type) {
      console.log(type)
      let data = {
        page: this.listpageNo,
        size: this.parameter.size,
      };
      myJhsList(data).then((res) => {
        if (type == "cre") {
          if (res.data.data != null) {
            this.history = res.data.data.rows;
            this.listtotal = res.data.data.total; //返回的总数据量
            this.listtotalPage = res.data.data.totalpage; //返回的总页数
          }
        } else {
          this.history = this.history.concat(res.data.data.rows);
        }
        if (type == "cre" || !type) {
          this.history.forEach((item, index) => {
            if (item.xzlist.length <= 2) {
              item.xzlist = item.xzlist;
              item = Object.assign(item, this.text_icon_is); //合并对象
            } else {
              item = Object.assign(item, this.text_icon_is); //合并对象
              item.te_ic = true;
            }
          });
        }
        if (this.listtotalPage <= this.listpageNo) {
          this.listFinished = true;
        } else {
          this.listFinished = false;
          this.$refs.scrollView.finishLoadMore();
        }
        this.loadflag = true;
      });
    },
    // 点击显示全部数据 改变icon
    bott(item, index) {
      console.log(index)
      this.history[index].te_ic = false
      console.log(this.history)
      this.$forceUpdate()
    },
    // 默认显示的数据 改变icon
    right(item, index) {
      this.history[index].te_ic = true
      this.$forceUpdate()
    },
    // 点击使用方案
    Usage_plan(item) {
      // useJhsInfo({ jhsno: item.jhsno }).then((res) => {
      //   setStorage(
      //     "use_programme_xzinfo",
      //     res.data.data.xzinfo && res.data.data.echofalg == false
      //       ? res.data.data.xzinfo
      //       : ""
      //   );
      //   setStorage(
      //     "use_programme_echo",
      //     res.data.data.echo && res.data.data.echofalg == true
      //       ? res.data.data.echo.prodlist
      //       : ""
      //   );
      //   setStorage(
      //     "use_programme_jhsinfo",
      //     res.data.data.jhsinfo ? res.data.data.jhsinfo : ""
      //   );
      //   this.$router.push({
      //     path: "/makeout",
      //     query: {
      //       type: "1",
      //       cpcode: item.cpcode,
      //       ishas: res.data.data.echofalg,
      //       equal:res.data.data.echo.equal
      //     },
      //   });
      // });
        this.$router.push({
          path: "/makeout",
          query: {
            type: "1",
            cpcode: item.cpcode,
            jhsno:item.jhsno
          },
        });
    },
    // 点击删除我的计划书的记录
    detail_jhs(item, index) {
      Dialog.confirm({
        title: "确认",
        content: "请确认是否进行操作",
        confirmText: "确定",
        onConfirm: () =>
          delJhsNo({ jhsno: item.jhsno }).then((res) => {
            this.history.splice(index, 1);
          }),
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.history_jhs {
  padding: 0.4rem;

  .make_time {
    display: flex;
    padding-bottom: 0.4rem;
    border-bottom: 1px solid #EBEBEB;

    .back_color {
      width: 0.1rem;
      height: 0.5rem;
      margin-right: 0.4rem;
    }
  }

  .heade {
    display: flex;
    margin-top: 0.5rem;
    justify-content: space-between;

    i {
      align-self: center;
      font-size: 0.5rem;
    }
  }

  .text {
    // margin-left: -12%;
    height: 100%;
    display: flex;
    justify-content: start;
    width: 90%;

    >img {
      width: 12.5%;
    }

    >div {
      margin-left: 0.3rem;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    >div:first-child {
      margin-bottom: 0.4rem;
      font-weight: 700;
    }
  }
}

table {
  border-top: 1px solid #999;
  border-left: 1px solid #999;
  border-spacing: 0;
  width: 100%;
  text-align: center;
  align-items: center;
  color: #929191;
  line-height: 0.6rem;
  margin-top: 0.4rem;

  tr {
    width: 100%;

    td:first-child {
      width: 3rem;
    }
  }
}

table td {
  padding: 0.05rem 0.1rem;
  border-bottom: 1px solid #999;
  border-right: 1px solid #999;
}

.td {
  display: flex;
  justify-content: space-between;

  img {
    width: 0.6rem;
    align-self: center;
  }
}

.all_btn {
  display: flex;
  justify-content: space-between;
  margin-top: 0.3rem;

  .del {
    padding: 0.3rem;
    width: 40%;
    border: 1px solid #E6E6E6;
    text-align: center;
    border-radius: 0.3rem;
  }

  .can {
    padding: 0.3rem;
    width: 55%;
    border: 1px solid #E6E6E6;
    text-align: center;
    border-radius: 0.3rem;
    background-color: #FE6736;
    color: white;
  }
}

.zkmx {
  width: 35%;
  text-align: center;
  margin: 0 auto;
  margin-top: 0.15rem;
}

.list {
  height: 93%;
}
</style>